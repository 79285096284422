import './NewFeedBackCSS.css'

export const AlreadySubmittedJS = () => {
    return(
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-10">
                        <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                            <div className="heading">
                                <img src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png" alt="Humming Bird" />
                            </div>
                            <form id="msform">
                                <div className="Thankyou">
                                    <h2>Already you have submitted your feedback!</h2>
                                    <img className="ThankyouImage" src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/confirmation-email-template-image/thank_you_gif.gif"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}